import { Modal, ConfigProvider, Popover } from 'antd';
import { createRoot } from 'react-dom/client';
import { QueryOrderModal } from './QueryOrderModal';
import orderDemo from '../../assets/order-demo.jpg';

import { legacyLogicalPropertiesTransformer, StyleProvider } from '@ant-design/cssinjs';
import { globalData } from '../../Service/utils';

export function QueryOrder(props = {}) {
    const div = document.createElement('div');
    document.body.appendChild(div);
    const root = createRoot(div);

    function remove() {
        root.unmount();
        div.parentNode.removeChild(div);
    }

    return new Promise((resolve) => {
        const handleOk = (result) => {
            // submit to server success
            globalData.timerId && window.clearTimeout(globalData.timerId);
            resolve(result);

            remove();
        };

        const handleCancel = () => {
            globalData.timerId && window.clearTimeout(globalData.timerId);
            resolve(false);
            remove();
        };

        root.render(
            <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#24be58',
                            colorInfoBg: '#fffbe6',
                            colorInfoActive: '#f7941d',
                            colorInfoText: '#f7941d',
                        },
                    }}
                >
                    <Modal
                        destroyOnClose={true}
                        open={true}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        bodyStyle={{ padding: '0px' }}
                        title={
                            <div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    请输入交易单号或兑换码查看
                                    <Popover
                                        content={
                                            <div>
                                                <div
                                                    style={{ marginBottom: '10px', width: '560px' }}
                                                >
                                                    温馨提示：
                                                    <div>
                                                        1.为了产出更高质量的文档，预计10分钟左右生成完成
                                                    </div>
                                                    <div>
                                                        2.文件下载流程：微信打开账单，找到对应的支付记录，点开详情页复制订单号/交易单号后，查询结果输入编号即可下载对应的文件
                                                    </div>
                                                    <div>
                                                        3.所有全文及文档都应该由本人通过学习后认真撰写，AI范文相关内容仅视为格式参考，不允许抄袭、代写、直接挪用等行为。
                                                    </div>
                                                </div>

                                                <img
                                                    src={orderDemo}
                                                    style={{ width: '560px', height: 'auto' }}
                                                />
                                            </div>
                                        }
                                        title="获取交易单号"
                                        trigger="hover"
                                    >
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                marginLeft: '10px',
                                                color: '#666',
                                            }}
                                        >
                                            如何获取交易单号
                                        </span>
                                    </Popover>
                                </div>
                            </div>
                        }
                    >
                        <QueryOrderModal
                            {...props}
                            handleCancel={handleCancel}
                            handleOk={handleOk}
                        />
                    </Modal>
                </ConfigProvider>
            </StyleProvider>,
        );
    });
}
