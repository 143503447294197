import { makeAutoObservable, runInAction } from 'mobx';
import { sleep } from '../../helper';
import { post, get } from '../../axios/axios';
import { message, Modal } from 'antd';
import { baseURL } from '../../axios/axios';
import { searchResultEventSource } from '../helper/api';
import { getQuery } from '../../common/query';
import moment from 'moment';
import { DOCTYPEENUM, DOCTYPE_UID_TYPE_MAP } from '../Documents/const';
import {
    AttentionEchartsOption1,
    SubjectWordsEchartsOption2,
    RelevantEchartsOption3,
} from './mock';
import lilunkuangjia from './assets/lilunkuangjia.svg';
import yanjiubeijing from './assets/yanjiubeijing.svg';
import yanjiujuxian from './assets/yanjiujuxian.svg';
import zhengtigaikuo from './assets/zhengtigaikuo.svg';
import chuangxin from './assets/chuangxin.svg';
import fenxijieguo from './assets/fenxijieguo.svg';
import zonghepingjia from './assets/zonghepingjia.svg';
import yanjiuxianzhuang from './assets/yanjiuxianzhuang.svg';

export const SearchType = {
    WenXianYueDuSingle: 1,
    WenXianYueDuMulit: 2,
};

function convertToEchartKGraphData(data) {
    const { edges, nodes } = data;
    return {
        title: {
            text: '学术关注度',
            top: 13,
            left: 12,
            textStyle: {
                fontSize: 14,
            },
        },
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        tooltip: {
            trigger: 'item',
            position: 'right',
            appendToBody: true,
            confine: true,
            renderMode: 'html',
        },
        series: [
            {
                type: 'graph',
                layout: 'none',
                top: 60,
                data: nodes.map(function (node) {
                    return {
                        x: node.x,
                        y: node.y,
                        id: node.id,
                        name: node.label,
                        symbolSize: node.size,
                        itemStyle: {
                            // color: node.color,
                            color: `rgb(${Math.random() * 255},${Math.random() * 255},${
                                Math.random() * 255
                            })`,
                        },
                    };
                }),
                edges: edges.map(function (edge) {
                    return {
                        source: edge.sourceID,
                        target: edge.targetID,
                    };
                }),
                roam: true,
                lineStyle: {
                    width: 0.5,
                    curveness: 0.3,
                    opacity: 0.7,
                },
            },
        ],
    };
}

class Store {
    constructor() {
        this.reset();
        makeAutoObservable(this);
    }
    reset() {
        this.title = '';
        this.subTitle = '';
        this.tabType = 1; // 大纲上面的tabs的值
        this.singleData = {
            o1: {
                title: '研究背景与意义',
                content: '',
                icon: lilunkuangjia,
            },
            o2: {
                title: '研究内容及创新点',
                content: '',
                icon: chuangxin,
            },
            o3: {
                title: '主要分析结果',
                content: '',
                icon: fenxijieguo,
            },
            o4: {
                title: '综合评价与建议',
                content: '',
                icon: zonghepingjia,
            },
            o5: {
                title: '研究的局限性与未来研究方向',
                content: '',
                icon: yanjiujuxian,
            },
            o6: {
                title: '总结',
                content: '',
                icon: zonghepingjia,
            },
        };
        this.multiData = {
            o1: {
                title: '研究背景和目的',
                content: '',
                icon: yanjiubeijing,
            },
            o2: {
                title: '理论框架和研究方法',
                content: '',
                icon: lilunkuangjia,
            },
            o3: {
                title: '研究发现与结论',
                content: '',
                icon: zonghepingjia,
            },
            o4: {
                title: '研究的局限性和未来研究方向',
                content: '',
                icon: yanjiujuxian,
            },
        };

        // 专业全文开题报告文献综述
        this.longData = {
            o1: {
                title: '全文创新点',
                content: '',
                icon: chuangxin,
            },
            o2: {
                title: '理论框架',
                content: '',
                icon: lilunkuangjia,
            },
            o3: {
                title: '非共识研究观点',
                content: '',
                icon: zonghepingjia,
            },
            o4: {
                title: '国内研究现状',
                content: '',
                icon: yanjiuxianzhuang,
            },
            o5: {
                title: '国外研究现状',
                content: '',
                icon: yanjiuxianzhuang,
            },
        };
        // PPT
        this.pptData = {
            o1: {
                title: '研究背景与意义',
                content: '',
                icon: yanjiubeijing,
            },
            o2: {
                title: '研究内容及创新点',
                content: '',
                icon: chuangxin,
            },
            o3: {
                title: '主要分析结果',
                content: '',
                icon: fenxijieguo,
            },
            o4: {
                title: '综合评价与建议',
                content: '',
                icon: zonghepingjia,
            },
            o5: {
                title: '研究的局限性与未来展望',
                content: '',
                icon: yanjiujuxian,
            },
            o6: {
                title: '总结',
                content: '',
                icon: zonghepingjia,
            },
        };
        // 文献阅读
        this.wenXianYueDuData = {
            xueShuGuanZhuDu: {},
            ciYunChartData: {},
        };
        this.isSingle = true;
        this.curLoadOIndex = 0;
        this.isLoadFinish = false;
        this.referenceList = [];
        this.query = {};
        this.pptQuery = {};
        this.pptTitle = '';
        this.pptSubTitle = '';
        this.outlines = [];
        this.outlineLoading = true;
        this.isLoadingPreview = false;
        this.attentionCharts = null;
        this.subjectCharts = null;
        this.relevanCharts = null;
        this.isLoadingChart = false;
        //全文预览
        this.hasPreviewEnd = false;
        this.nextPreviewLoading = false;
        this.paperInfo = null;
        this.showPreview = false;
        this.showPreviewLoading = false;
        this.yujiXiaohao = { str: '' };
        this.showCheckTableDialog = false;
        this.autoLightTypes = [];
        this.searchHasToken = false; // 是否返回token
    }

    onAutoLightChange = (list) => {
        this.autoLightTypes = list;
    };

    initByQuery(q) {
        this.query = q;
        this.title = q.query;
        this.subTitle = '小墨学术研究中心 ' + moment().format('yyyy-MM-DD');
        this.setIsSingle(+q.file <= 1);
        const docType = +q.docType;
        if (docType === 5) {
            this.tabType = 2;
        } else {
            this.tabType = docType;
        }
    }

    changeTabType(v) {
        this.tabType = v;
    }

    // 大纲页面
    initByOutlineQuery(q) {
        this.query = q;
        this.title = q.query;

        this.getOutline();
    }
    // ppt页面
    initByPPT(q) {
        console.log('q', q);

        this.pptQuery = q;
        this.getPPTOutline();
    }

    getPPTOutline = async () => {
        this.outlineLoading = true;
        this.outlines = [];
        try {
            const res = await post('ai/ai/s/ppt/outline?uid=' + this.pptQuery.uid, {
                query: this.pptQuery.query,
                prompt: this.pptQuery.prompt,
            });
            this.yujiXiaohao = res.data.inkDot;
            this.outlines = res.data.outlines;
            if (res.data?.pptTitle !== undefined && res.data?.pptTitle !== null) {
                this.pptTitle = res.data.pptTitle;
            }
            if (res.data?.pptSubTitle !== undefined && res.data?.pptSubTitle !== null) {
                this.pptSubTitle = res.data.pptSubTitle;
            }
        } catch (error) {
            console.error('Error generating outline:', error);
        } finally {
            this.outlineLoading = false;
        }
    };
    getOutline = async () => {
        this.outlineLoading = true;
        this.outlines = [];
        try {
            const res = await post('ai/ai/s/ppt/outline?uid=' + this.query.uid, {
                query: this.query.query,
                prompt: this.query.prompt,
            });
            this.yujiXiaohao = res.data.inkDot;
            this.outlines = res.data.outlines;
            if (res.data?.pptTitle !== undefined && res.data?.pptTitle !== null) {
                this.pptTitle = res.data.pptTitle;
            }
            if (res.data?.pptSubTitle !== undefined && res.data?.pptSubTitle !== null) {
                this.pptSubTitle = res.data.pptSubTitle;
            }
        } catch (error) {
            console.error('Error generating outline:', error);
        } finally {
            this.outlineLoading = false;
        }
    };

    get renderData() {
        if (DOCTYPE_UID_TYPE_MAP[+this.query.docType] === 1) {
            return this.longData;
        }
        if (+this.query.docType === 4) {
            return this.pptData;
        }
        return +this.query.file <= 1 ? this.singleData : this.multiData;
    }

    get renderFields() {
        let list = [];
        if (DOCTYPE_UID_TYPE_MAP[+this.query.docType] === 1) {
            list = this.longData;
            return Object.keys(list);
        }
        if (+this.query.docType === 4) {
            list = this.pptData;
            return Object.keys(list);
        }
        list = +this.query.file <= 1 ? this.singleData : this.multiData;
        return Object.keys(list);
    }

    setIsSingle(isSingle) {
        this.isSingle = isSingle;
    }

    setRenderData(field, newText) {
        const data = this.renderData;
        let newContent = data[field].content + newText;

        data[field].content = newContent;
    }

    async getReferenceList() {
        try {
            const uid = getQuery().id;
            const result = await get('/ai/literature/get/references', { uid });
            this.referenceList = result.data;
        } catch (error) {
            console.error('Error fetching reference list:', error);
        }
    }

    setOutlineSelectedExtra = () => {
        this.outlines = this.outlines.map((it) => {
            const calcSelectedExtra = (child) => {
                const selectedExtraList = this.autoLightTypes.reduce((sum, cur) => {
                    if (child.extraTypeCn && child.extraTypeCn[cur]) {
                        sum.push(cur);
                    }
                    return sum;
                }, []);
                return selectedExtraList.join(',') || '';
            };

            it.sections &&
                it.sections.forEach((section) => {
                    section.selected_extra = calcSelectedExtra(section);
                    section.subsections &&
                        section.subsections.forEach((subsection) => {
                            subsection.selected_extra = calcSelectedExtra(subsection);
                        });
                });

            return it;
        });

        this.refreshInkDot();
    };

    async getChart() {
        const uid = this.query.id;
        this.isLoadingChart = true;
        if (DOCTYPE_UID_TYPE_MAP[+this.query.docType] === 1) {
            try {
                // const [attentionRes, subjectRes, relevanceRes] = await Promise.all([
                //     get('/ai/ai/getAttention', { uid }),
                //     get('/ai/ai/getSubject', { uid }),
                //     get('/ai/ai/getRelevanceWords', { uid }),
                // ]);
                const res = await get('/ai/ai/get/chart', {
                    uid,
                });
                const { att: attentionRes, subject: subjectRes, words: relevanceRes } = res.data;
                const attentionChart = attentionRes;
                const years = attentionChart.attentionCs.map((it) => it.year);
                const csCount = attentionChart.attentionCs.map((it) => it.count);
                const fsCount = attentionChart.attentionFs.map((it) => it.count);
                const csRatio = attentionChart.attentionCsGrowthRate.map((it) =>
                    (it.count / 100).toFixed(2),
                );
                const fsRatio = attentionChart.attentionFsGrowthRate.map((it) =>
                    (it.count / 100).toFixed(2),
                );
                this.attentionCharts = AttentionEchartsOption1(
                    this.title,
                    years,
                    csCount,
                    fsCount,
                    csRatio,
                    fsRatio,
                );
                const relevanChart = relevanceRes;
                const words = relevanChart.map((it) => it.key);
                const counts = relevanChart.map((it) => it.count);
                this.relevanCharts = RelevantEchartsOption3(words, counts);

                const subjectChart = subjectRes;
                this.subjectCharts = SubjectWordsEchartsOption2(
                    subjectChart.map((it) => ({
                        value: it.count,
                        name: it.key,
                    })),
                );
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
        }

        if (+this.query.docType === DOCTYPEENUM.WENXIAN_YUEDU) {
            try {
                const [relationRes, wordCloudRes] = await Promise.all([
                    get('/ai/literature/relationChart', { uid }),
                    get('ai/literature/getWords', { uid }),
                ]);
                this.wenXianYueDuData.xueShuGuanZhuDu = convertToEchartKGraphData(relationRes.data);
                this.wenXianYueDuData.ciYunChartData = wordCloudRes.data;
            } catch (error) {
                console.error('Error fetching literature data:', error);
            }
        }
        this.isLoadingChart = false;
    }

    async getSearchResult() {
        return new Promise((resolve) => {
            const q = getQuery();
            const uid = getQuery().id;
            let payload = undefined;
            // 文献阅读接口
            let url =
                baseURL +
                `/ai/literature/connect?uid=${uid}&query=${q.query}&docSource=${
                    q.docSource ? true : false
                }&docType=5`;
            // 长文 开题 综述
            if (DOCTYPE_UID_TYPE_MAP[+q.docType] === 1) {
                url = baseURL + `/ai/ai/search`;

                payload = JSON.stringify({
                    uid: uid,
                    query: q.query,
                    recommendedReferences: q.rr ? true : false,
                    docSource: q.docSource ? true : false,
                });
            }
            if (+q.docType === DOCTYPEENUM.PPT) {
                url = baseURL + `/ai/ai/s/ppt/gen`;
                payload = JSON.stringify({
                    uid,
                    query: q.query,
                });
            }

            this.curLoadOIndex = 0;
            let curOIndex = 0;
            const _debugTokens = [];
            const onUpdate = (singleText) => {
                console.log('singleText:', singleText);
                _debugTokens.push(singleText);

                this.searchHasToken = true;
                const curField = `o${curOIndex}`;
                const nextField = `o${curOIndex + 1}`;
                if (singleText === `[${nextField}]`) {
                    console.log('match nextField:', nextField);
                    curOIndex++;
                    this.curLoadOIndex = curOIndex;
                    return;
                }
                // console.log(singleText);
                this.setRenderData(curField, singleText);
            };
            const onfinish = (params) => {
                this.isLoadFinish = true;
                console.log('the fields to render:', this.renderFields);
                console.log('the data to render:', this.renderData);
                console.log('all text:', _debugTokens.join(''));
                resolve();
            };
            this.eventSource = searchResultEventSource(url, null, onfinish, payload, onUpdate);
        });
    }

    // 大纲页面
    addTitle(opts) {
        if (this.isLoadingPreview) {
            message.info('全文预览生成中，请结束后再试');
            return;
        }

        const {
            level,
            level1idx,
            level2idx,
            level3idx,
            inputMain,
            inputSub,
            isAfter,
            insertType, // 'same' | 'sub'
            selectedSubChapter, // 选中的子章节索引
        } = opts;

        // 插入同级章节
        if (insertType === 'same') {
            const idxDiff = isAfter ? 1 : 0;

            if (level === 1) {
                this.outlines.splice(level1idx + idxDiff, 0, {
                    chapter: inputMain,
                    sections: [],
                });
            } else if (level === 2) {
                this.outlines[level1idx].sections.splice(level2idx + idxDiff, 0, {
                    chapter: inputMain,
                    chapterAbstract: inputSub,
                    extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                    selected_extra: '',
                    subsections: [],
                });
            } else if (level === 3) {
                this.outlines[level1idx].sections[level2idx].subsections.splice(
                    level3idx + idxDiff,
                    0,
                    {
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                    },
                );
            }
            return;
        }

        // 插入子级章节
        if (insertType === 'sub') {
            // 一级章节插入节
            if (level === 1) {
                const sections = this.outlines[level1idx].sections;
                // 如果没有子节，直接添加到末尾
                if (!sections.length) {
                    sections.push({
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                        subsections: [],
                    });
                } else {
                    // 有子节时，根据选择的位置插入
                    const insertIdx = selectedSubChapter + (isAfter ? 1 : 0);
                    sections.splice(insertIdx, 0, {
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                        subsections: [],
                    });
                }
            }
            // 二级节插入小节
            else if (level === 2) {
                const subsections = this.outlines[level1idx].sections[level2idx].subsections;
                // 如果没有小节，直接添加到末尾
                if (!subsections.length) {
                    subsections.push({
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                    });
                } else {
                    // 有小节时，根据选择的位置插入
                    const insertIdx = selectedSubChapter + (isAfter ? 1 : 0);
                    subsections.splice(insertIdx, 0, {
                        chapter: inputMain,
                        chapterAbstract: inputSub,
                        extraType: 'NORMAL,CODE,TABLE,DIAGRAM,FORMULA',
                        selected_extra: '',
                    });
                }
            }
        }
    }

    editTitle(opts) {
        if (this.isLoadingPreview) {
            message.info('全文预览生成中，请结束后再试');
            return;
        }

        const { level, level1idx, level2idx, level3idx, inputMain, inputSub } = opts;
        if (level === 1) {
            this.outlines[level1idx] = {
                ...this.outlines[level1idx],
                chapter: inputMain,
            };
        } else if (level === 2) {
            this.outlines[level1idx].sections[level2idx] = {
                ...this.outlines[level1idx].sections[level2idx],
                chapter: inputMain,
                chapterAbstract: inputSub,
            };
        } else if (level === 3) {
            this.outlines[level1idx].sections[level2idx].subsections[level3idx] = {
                ...this.outlines[level1idx].sections[level2idx].subsections[level3idx],
                chapter: inputMain,
                chapterAbstract: inputSub,
            };
        }
    }

    deleteTitle(opts) {
        if (this.isLoadingPreview) {
            message.info('全文预览生成中，请结束后再试');
            return;
        }

        const { level, level1idx, level2idx, level3idx, txt } = opts;
        Modal.confirm({
            title: '确认删除吗',
            content: `确认删除章节<${txt}>吗？章节不足可能会导致全文总体字数不足`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                if (level === 1) {
                    this.outlines.splice(level1idx, 1);
                } else if (level === 2) {
                    this.outlines[level1idx].sections.splice(level2idx, 1);
                } else if (level === 3) {
                    this.outlines[level1idx].sections[level2idx].subsections.splice(level3idx, 1);
                }
            },
            onCancel: () => {},
        });
    }
    changeTitleExtra(opts) {
        if (this.isLoadingPreview) {
            message.info('全文预览生成中，请结束后再试');
            return;
        }

        const { v, level, level1idx, level2idx, level3idx } = opts;
        const { checked, type } = v;
        if (level === 1) {
        } else if (level === 2) {
            const origin = this.outlines[level1idx].sections[level2idx].selected_extra;
            this.outlines[level1idx].sections[level2idx] = {
                ...this.outlines[level1idx].sections[level2idx],
                selected_extra: modifySelectExtra({ origin, checked, type }),
            };
        } else if (level === 3) {
            const origin =
                this.outlines[level1idx].sections[level2idx].subsections[level3idx].selected_extra;
            this.outlines[level1idx].sections[level2idx].subsections[level3idx] = {
                ...this.outlines[level1idx].sections[level2idx].subsections[level3idx],
                selected_extra: modifySelectExtra({ origin, checked, type }),
            };
        }

        this.refreshInkDot();
    }

    refreshInkDot = async () => {
        try {
            const res = await post('/ai/w/paper/outline/c', {
                uid: this.query.id,
                outlines: this.outlines,
            });
            this.yujiXiaohao = res.data;
        } catch (error) {}
    };
    // 预览页面
    genPreviewPaper = async () => {
        try {
            this.showPreviewLoading = true;

            await post('/ai/ai/paper/create/paper', {
                uid: this.query.id,
                title: this.title,
                outlines: this.outlines,
            });
            this.showPreview = true;
            this.showPreviewLoading = false;
        } catch (error) {
            this.showPreviewLoading = false;
        }
    };

    updateOutline = async () => {
        try {
            await post('/ai/ai/s/paper/update/outlines', {
                outlines: this.outlines,
                title: this.title,
                uid: this.query.id,
            });
        } catch (error) {
            console.error(error);
        } finally {
        }
    };
}

export const searchResultStore = new Store();

function modifySelectExtra(opt) {
    const { origin, checked, type } = opt;
    const originList = origin?.split(',') || [];
    const isCheckedBefore = originList.includes(type);
    if (!isCheckedBefore && checked) {
        originList.push(type);
        return originList.join(',');
    }
    if (isCheckedBefore && !checked) {
        const idx = originList.indexOf(type);
        originList.splice(idx, 1);
        return originList.join(',');
    }
    return origin;
}
