import { Button, Layout, Space, Tree, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './index.module.scss';

import { DownloadOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { convertToTreeStructure, summaryStore } from '../store';

import { SmileOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';
import { Result } from 'antd';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { get, post } from '../../../axios/axios';
import { useDevice } from '../../../hooks/useDevice';
import { DownloadPaperModal } from '../Step3/components/DownloadPaperModal';
import AcademicEditor from './components/AcademicEditor/AcademicEditor';
import AiModal from './components/AiModal';
import ExpansionModal from './components/ExpansionModal';
import ImgModal from './components/ImgModal';
import InsertModal from './components/InsertModal';
import LessenModal from './components/LessenModal';
import RepetitionModal from './components/RepetitionModal';
import RewriteModal from './components/RewriteModal';
import TableModal from './components/TableModal';
import ai from './images/icon_AI.svg';
import chart from './images/icon_chart.svg';
import expansion from './images/icon_expansion.svg';
import img from './images/icon_img.svg';
import lessen from './images/icon_lessen.svg';
import repetition from './images/icon_repetition.svg';
import rewrite from './images/icon_rewrite.svg';
import { defaultOrderNo } from '../../../components/Nav';
const { Sider, Content } = Layout;

const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

export const Editor = observer(() => {
    const editorRef = useRef(null);
    const [paper, setPaper] = useState(summaryStore.paperInfo || {});

    const location = useLocation();
    const [qs] = useSearchParams();
    const [catalogueData, setCatalogueData] = useState('');
    const [catalogueDataLoaded, setCatalogueDataLoaded] = useState(false);
    // 获取编辑器大纲和目录
    const getCatalogueData = async () => {
        try {
            const res = await get('/ai/ai/paper/editor/get/outline', {
                orderNo: qs.get('orderNo'),
            });

            setCatalogueData(convertToTreeStructure(res.data));
            setCatalogueDataLoaded(true);
        } catch (e) {
            setCatalogueDataLoaded(false);
        }
    };
    const [abstractDataLoaded, setAbstractDataLoaded] = useState(false);
    const [abstractDataInceptionData, setAbstractDataInceptionData] = useState({});
    // 获取编辑器摘要
    const getAbstractData = async () => {
        try {
            const res = await get('/ai/ai/paper/editor/get/abstract', {
                orderNo: qs.get('orderNo'),
            });

            setAbstractDataInceptionData(res.data);
            setAbstractDataLoaded(true);
        } catch (e) {
            setAbstractDataLoaded(false);
        }
    };
    const [contentDataLoaded, setContentDataLoaded] = useState(false);
    const [contentInceptionData, setContentInceptionData] = useState({});
    // 获取编辑器内容
    const getContentData = async () => {
        try {
            setContentDataLoaded(false);
            const res = await get('/ai/ai/paper/editor/get/content', {
                orderNo: qs.get('orderNo'),
            });

            setContentInceptionData(res.data);
            setContentDataLoaded(true);
        } catch (e) {
            setContentDataLoaded(false);
        }
    };

    const isDefaultOrderNo = useMemo(() => {
        return qs.get('orderNo') === defaultOrderNo;
    }, [qs]);

    // 默认订单禁用编辑器
    const setEditorDisabled = useCallback(() => {
        editorRef.current.setEditable(false);
    }, []);

    useEffect(() => {
        if (isDefaultOrderNo && contentDataLoaded) {
            setEditorDisabled();
        }
    }, [contentDataLoaded]);

    useEffect(() => {
        getCatalogueData();
        getAbstractData();
        getContentData();
    }, [location.search]);

    const siderStyle = {
        overflow: 'auto',
        height: 'calc(100vh - 56px)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        backgroundColor: '#fff',
    };

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    // 展开/收起按钮样式
    const toggleButtonStyle = {
        position: 'absolute',
        right: '-16px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '32px',
        height: '32px',
        backgroundColor: '#fff',
        border: '1px solid #EEEEF1',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 100,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    };

    // 一键降重
    const useRepetitionModal = useModal(RepetitionModal);
    // 降AI率
    const useAiModal = useModal(AiModal);
    // 章节扩写
    const useExpansionModal = useModal(ExpansionModal);
    // 章节缩写
    const useLessenModal = useModal(LessenModal);
    // 章节插文
    const useInsertModal = useModal(InsertModal);
    // 重写章节
    const useRewriteModal = useModal(RewriteModal);
    // 修改图片
    const useImgModal = useModal(ImgModal);
    // 修改表格
    const useTableModal = useModal(TableModal);

    const Tools = useMemo(() => {
        return [
            { id: 'reduce', title: '降重', icon: repetition, modal: useRepetitionModal },
            // { id: 'ai', title: '降AI率', icon: ai, modal: useAiModal },
            { id: 'expand', title: '扩写', icon: expansion, modal: useExpansionModal },
            { id: 'shrink', title: '缩写', icon: lessen, modal: useLessenModal },
            { id: 'rewrite', title: '重写章节', icon: rewrite, modal: useRewriteModal },
            // { id: 'insert', title: '插文', icon: insert, modal: useInsertModal },
            { id: 'image', title: '修图', icon: img, modal: useImgModal },
            { id: 'table', title: '修表', icon: chart, modal: useTableModal },
        ];
    }, []);

    const handleToolClick = useCallback((e, item) => {
        e.preventDefault(); // 阻止默认行为
        e.stopPropagation(); // 阻止事件冒泡

        clickTool(item);
    }, []);

    const setEditorFocus = (delay = 10) => {
        setTimeout(() => {
            editorRef.current.setFocus();
        }, delay);
    };
    const clickTool = useCallback((item) => {
        editorRef.current.handleButtonClick(item.id);
    }, []);

    // 当前是否有modal打开
    const [isOpeningModal, setIsOpeningModal] = useState(false);
    const mlVal = useMemo(() => {
        // 如果弹窗打开，偏移量加大
        if (isOpeningModal) {
            return '-36%';
        }
        // 如果只有collapsed，则偏移
        return collapsed ? 0 : '12%';
    }, [collapsed, isOpeningModal]);

    // 将替换内容同步到服务器
    const replaceContentToServer = async ({ chapterId, orderNo, original, replacementContent }) => {
        const _replaceContent = replacementContent.replace(
            /<sup>\[(\d+)\]<\/sup>/g,
            (match, num) => `[[citation:${num}]]`,
        );
        const res = await post('/ai/ai/paper/editor/replaceContent', {
            chapterId,
            orderNo,
            original,
            replacementContent,
        });
        return res.data;
    };

    // 将替换图或者表同步到服务器
    const replaceTableOrImgToServer = async ({ chapterId, orderNo, id, type, tableContent }) => {
        const params = {
            chapterId,
            orderNo,
            id,
            type,
        };
        if (type === 'table') {
            params.tableContent = tableContent;
        }
        const res = await post('/ai/ai/paper/editor/replace', params);
        return res.data;
    };

    // 关闭modal
    const handleCloseModal = () => {
        setCollapsed(false);
        setIsOpeningModal(false);
        // editorRef.current.setEditable(true);
    };

    // 根据type显示不同的modal
    const handleOpenModal = ({ type, content, from, to, chapterId, id = '' }) => {
        setCollapsed(true);
        setIsOpeningModal(true);
        // editorRef.current.setEditable(false);

        switch (type) {
            case 'reduce':
                useRepetitionModal
                    .show({
                        content,
                        orderNo: qs.get('orderNo'),
                        from,
                        to,
                        chapterId,
                    })
                    .then((newContent) => {
                        handleCloseModal();
                        editorRef.current.updateTextContent(from, to, newContent);
                        replaceContentToServer({
                            chapterId,
                            orderNo: qs.get('orderNo'),
                            original: content,
                            replacementContent: newContent,
                        });
                    })
                    .catch(() => {
                        handleCloseModal();
                    });
                break;
            case 'ai':
                useAiModal
                    .show({
                        content,
                        orderNo: qs.get('orderNo'),
                        from,
                        to,
                        chapterId,
                    })
                    .then((newContent) => {
                        handleCloseModal();
                        editorRef.current.updateTextContent(from, to, newContent);
                        replaceContentToServer({
                            chapterId,
                            orderNo: qs.get('orderNo'),
                            original: content,
                            replacementContent: newContent,
                        });
                    })
                    .catch(() => {
                        handleCloseModal();
                    });
                break;
            case 'expand':
                useExpansionModal
                    .show({
                        content,
                        orderNo: qs.get('orderNo'),
                        from,
                        to,
                        chapterId,
                    })
                    .then((newContent) => {
                        handleCloseModal();
                        editorRef.current.updateTextContent(from, to, newContent);
                        replaceContentToServer({
                            chapterId,
                            orderNo: qs.get('orderNo'),
                            original: content,
                            replacementContent: newContent,
                        });
                    })
                    .catch(() => {
                        handleCloseModal();
                    });
                break;
            case 'shrink':
                useLessenModal
                    .show({
                        content,
                        orderNo: qs.get('orderNo'),
                        from,
                        to,
                        chapterId,
                    })
                    .then((newContent) => {
                        handleCloseModal();
                        editorRef.current.updateTextContent(from, to, newContent);
                        replaceContentToServer({
                            chapterId,
                            orderNo: qs.get('orderNo'),
                            original: content,
                            replacementContent: newContent,
                        });
                    })
                    .catch(() => {
                        handleCloseModal();
                    });
                break;
            // case 'insert':
            //     useInsertModal
            //         .show({
            //             content,
            //             orderNo: qs.get('orderNo'),
            //             from,
            //             to,
            //             chapterId,
            //             setEditorFocus,
            //         })
            //         .then((text) => {
            //             handleCloseModal();
            //             editorRef.current.insertContentAt(from, to, text);
            //             replaceContentToServer({
            //                 chapterId,
            //                 orderNo: qs.get('orderNo'),
            //                 original: content,
            //                 replacementContent: text,
            //             });
            //         })
            //         .catch(() => {
            //             handleCloseModal();
            //         });
            //     break;
            case 'rewrite':
                useRewriteModal
                    .show({
                        content,
                        orderNo: qs.get('orderNo'),
                        from,
                        to,
                        chapterId,
                    })
                    .then(async () => {
                        handleCloseModal();
                        await getContentData();
                        setTimeout(() => {
                            editorRef.current.scrollToId(chapterId);
                        }, 500);
                    })
                    .catch(() => {
                        handleCloseModal();
                    });
                break;
            case 'image':
                useImgModal
                    .show({ content, orderNo: qs.get('orderNo'), from, to, chapterId, id })
                    .then((newSrc) => {
                        handleCloseModal();
                        editorRef.current.updateImageSrc(id, newSrc);
                        replaceTableOrImgToServer({
                            chapterId,
                            orderNo: qs.get('orderNo'),
                            id,
                            type: 'image',
                        });
                    })
                    .catch(() => {
                        handleCloseModal();
                    });
                break;
            case 'table':
                useTableModal
                    .show({ content, orderNo: qs.get('orderNo'), from, to, chapterId })
                    .then(async () => {
                        handleCloseModal();
                        console.log('content', content);

                        await replaceTableOrImgToServer({
                            chapterId,
                            orderNo: qs.get('orderNo'),
                            type: 'table',
                            tableContent: content,
                        });
                        await getContentData();
                        setTimeout(() => {
                            editorRef.current.scrollToId(chapterId);
                        }, 500);
                    })
                    .catch(() => {
                        handleCloseModal();
                    });
                break;
            default:
                break;
        }
    };

    const [searchParams] = useSearchParams();
    const useDownloadPaperModal = useModal(DownloadPaperModal);
    // 进行全文下载
    const showOrderModal = () => {
        const orderNo = searchParams.get('orderNo');
        useDownloadPaperModal.show({
            orderNo,
            queryType: 'query2',
        });
    };

    const [wordCount, setWordCount] = useState(0);
    const getWordCount = (editorWordCount) => {
        setWordCount(editorWordCount);
    };

    const { isMobile } = useDevice();

    const navigate = useNavigate();

    return (
        <>
            {isMobile ? (
                <div className="flex h-screen w-screen items-center justify-center">
                    <Result
                        icon={<SmileOutlined />}
                        title="为了获得更好的使用体验，请在PC端使用"
                        extra={
                            <Button type="primary" onClick={() => navigate('/')}>
                                返回首页
                            </Button>
                        }
                    />
                </div>
            ) : (
                <Layout
                    style={{
                        backgroundColor: '#fff',
                        marginTop: 12,
                        position: 'relative',
                    }}
                >
                    <Sider
                        width={'18%'}
                        collapsible
                        collapsed={collapsed}
                        trigger={null}
                        collapsedWidth={0}
                        style={{
                            ...siderStyle,
                            left: 0,
                            top: 56,
                            padding: 12,
                            borderRight: '1px solid #EEEEF1',
                            overflow: 'initial',
                            '&::WebkitScrollbar': {
                                width: '0px',
                            },
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                        }}
                    >
                        <div
                            className={'overflow-y-scroll'}
                            style={{ height: 'calc(100% - 32px)' }}
                        >
                            {catalogueDataLoaded && !collapsed ? (
                                <Tree
                                    defaultExpandAll={catalogueDataLoaded}
                                    showLine={false}
                                    showIcon={false}
                                    treeData={catalogueData}
                                    key={'id'}
                                    onSelect={(selectedKeys, info) => {
                                        console.log('selectedKeys', selectedKeys);
                                        console.log('info', info);
                                        editorRef.current.scrollToHeadingText(info.node.title);
                                    }}
                                />
                            ) : null}
                            {/* 展开/收起按钮 */}
                            <div style={toggleButtonStyle} onClick={toggleCollapsed}>
                                {collapsed ? <RightOutlined /> : <LeftOutlined />}
                            </div>
                        </div>
                        <div className={'px-4 py-2'}>
                            总字数：<span className={'font-bold'}>{wordCount}</span>
                        </div>
                    </Sider>
                    <Content
                        style={{
                            marginLeft: mlVal,
                            transition: 'all 0.3s',
                        }}
                    >
                        {!contentDataLoaded ? (
                            <div className="flex h-full flex-col items-center justify-center">
                                <Spin size="large" tip="正在加载..." />
                            </div>
                        ) : (
                            <div className={styles.result}>
                                <div className={styles.resultPage}>
                                    <div className={styles.editorWrapper}>
                                        {contentDataLoaded && (
                                            <AcademicEditor
                                                ref={editorRef}
                                                data={{
                                                    abstract_zh:
                                                        abstractDataInceptionData.abstractCn,
                                                    abstract_en:
                                                        abstractDataInceptionData.abstractEn,
                                                    main_body: contentInceptionData,
                                                }}
                                                onOpenModal={handleOpenModal}
                                                getWordCount={getWordCount}
                                                replaceContentToServer={replaceContentToServer}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Content>
                    <Sider
                        width={'104px'}
                        style={{
                            ...siderStyle,
                            right: 0,
                            top: 58,
                            height: 'calc(100vh - 58px)',
                            background: '#fff',
                        }}
                    >
                        <div className={'mb-8 mt-3 flex justify-end'}>
                            <Space>
                                {/* <Button icon={<SaveOutlined />} size={'mini'}>
                                            保存
                                        </Button> */}
                                <Button
                                    size={'mini'}
                                    icon={<DownloadOutlined />}
                                    type={'primary'}
                                    onClick={showOrderModal}
                                >
                                    下载
                                </Button>
                            </Space>
                        </div>
                        <div className="tools rounded bg-[#F8FBFF] p-6">
                            {Tools.map((item) => {
                                return (
                                    <ConditionalWrapper
                                        condition={isDefaultOrderNo}
                                        wrapper={(children) => (
                                            <Tooltip
                                                trigger={'click'}
                                                placement="left"
                                                title={'请下单后使用完整AI功能'}
                                            >
                                                {children}
                                            </Tooltip>
                                        )}
                                    >
                                        <div
                                            className="mb-8 cursor-pointer select-none"
                                            onMouseDown={(e) => {
                                                if (isDefaultOrderNo) return;
                                                handleToolClick(e, item);
                                            }}
                                        >
                                            <img
                                                src={item.icon}
                                                className="mx-auto block h-6 w-6"
                                                alt=""
                                            />
                                            <div className="mt-1 text-center text-sm">
                                                {item.title}
                                            </div>
                                        </div>
                                    </ConditionalWrapper>
                                );
                            })}
                        </div>
                    </Sider>
                </Layout>
            )}
        </>
    );
});
export default Editor;
