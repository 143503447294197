import { makeAutoObservable, runInAction } from 'mobx';
import { post, get } from '../../axios/axios';
import { uniqueId } from 'lodash';
const primarySections = [
    {
        id: uniqueId('step2'),
        title: '一、研究目的及意义',
        secondarySections: [
            {
                title: '1.1 研究目的',
                tertiaryTitles: [
                    '1.1.1 分析宿舍管理系统的安全隐患',
                    '1.1.2 提出有效的安全防护措施',
                    '1.1.3 提高宿舍管理系统的安全性和可靠性',
                ],
            },
        ],
    },
    {
        title: '二、研究现状',
        id: uniqueId('step2'),
        secondarySections: [
            {
                title: '2.1 国内外宿舍管理系统的发展概况',
                tertiaryTitles: [
                    '2.1.1 国内宿舍管理系统的发展',
                    '2.1.2 国外宿舍管理系统的发展',
                    '2.1.3 现有宿舍管理系统的安全问题',
                ],
            },
        ],
    },
    {
        id: uniqueId('step2'),
        title: '三、研究问题',
        secondarySections: [
            {
                title: '3.1 宿舍管理系统存在的安全隐患',
                tertiaryTitles: [],
            },
            {
                title: '3.2 如何提高宿舍管理系统的安全性',
                tertiaryTitles: [],
            },
            {
                title: '3.3 如何保障学生信息安全',
                tertiaryTitles: [],
            },
        ],
    },
    {
        id: uniqueId('step2'),
        title: '四、研究内容与方法',
        secondarySections: [
            {
                title: '4.1 研究内容',
                tertiaryTitles: [
                    '4.1.1 宿舍管理系统的安全隐患分析',
                    '4.1.2 安全防护措施的设计与实施',
                    '4.1.3 安全防护措施的效果评估',
                ],
            },
            {
                title: '4.2 研究方法',
                tertiaryTitles: ['4.2.1 文献分析法', '4.2.2 案例分析法', '4.2.3 实证研究法'],
            },
        ],
    },
    {
        id: uniqueId('step2'),
        title: '五、拟解决的问题与措施',
        secondarySections: [
            {
                title: '5.1 解决宿舍管理系统存在的安全隐患',
                tertiaryTitles: [],
            },
            {
                title: '5.2 设计并实施有效的安全防护措施',
                tertiaryTitles: [],
            },
            {
                title: '5.3 保障学生信息安全',
                tertiaryTitles: [],
            },
        ],
    },
    {
        id: uniqueId('step2'),
        title: '六、创新点',
        secondarySections: [
            {
                title: '6.1 针对宿舍管理系统的特点进行安全性评估',
                tertiaryTitles: [],
            },
            {
                title: '6.2 提出针对性的安全防护措施',
                tertiaryTitles: [],
            },
            {
                title: '6.3 通过实证研究验证防护措施的有效性',
                tertiaryTitles: [],
            },
        ],
    },
    {
        id: uniqueId('step2'),
        title: '七、全文框架',
        secondarySections: [
            {
                title: '7.1 引言',
                tertiaryTitles: [],
            },
            {
                title: '7.2 宿舍管理系统的安全隐患分析',
                tertiaryTitles: [],
            },
            {
                title: '7.3 安全防护措施的设计与实施',
                tertiaryTitles: [],
            },
            {
                title: '7.4 安全防护措施的效果评估',
                tertiaryTitles: [],
            },
            {
                title: '7.5 结论与建议',
                tertiaryTitles: [],
            },
        ],
    },
    {
        id: uniqueId('step2'),
        title: '八、工作进度安排',
        secondarySections: [
            {
                title: '8.1 第一阶段：文献调研与资料收集（1-2个月）',
                tertiaryTitles: [],
            },
            {
                title: '8.2 第二阶段：安全隐患分析与防护措施设计（3-4个月）',
                tertiaryTitles: [],
            },
            {
                title: '8.3 第三阶段：防护措施实施与效果评估（5-6个月）',
                tertiaryTitles: [],
            },
            {
                title: '8.4 第四阶段：全文撰写与修改（7-8个月）',
                tertiaryTitles: [],
            },
        ],
    },
].map((d) => ({
    id: uniqueId('step2'),
    ...d,
}));
class NewPPTStore {
    curStep = 0;
    nextLoading = false;
    uid = '';
    hs = 0; // 是否提交过生成PPT
    steps = [
        {
            title: '标题',
        },
        {
            title: '大纲',
        },
        {
            title: '模板',
        },
    ];
    // 标题
    step1 = {
        title: '',
        type: undefined,
        isCardNote: undefined,
        canSubmit: false,
        writingRecordId: '',
    };

    // 大纲
    step2 = {
        focusIdx: -1,
        mainTitle: '',
        subTitle: '',
        primarySections: [],
    };
    step3 = {
        focusIdx: 0,
        selectIdx: 1,
        templates: [],
    };

    paperTypes = [
        {
            label: '开题答辩',
            value: 1,
        },
        {
            label: '结题答辩',
            value: 2,
        },
    ];

    constructor() {
        makeAutoObservable(this);
        this.reset();
    }

    reset() {
        this.curStep = 0;
        this.nextLoading = false;
        this.hs = 0;
        this.steps = [
            {
                title: '标题',
            },
            {
                title: '大纲',
            },
            {
                title: '模板',
            },
        ];
        this.step1 = {
            title: '',
            type: undefined,
            canSubmit: false,
            isCardNote: undefined,
            writingRecordId: '',
        };
        this.step2 = {
            selectIdx: -1,
            focusIdx: -1,
            mainTitle: '',
            subTitle: '',
            primarySections: [],
        };

        this.step3 = {
            focusIdx: 0,
            selectIdx: 1,
            templates: [
                {
                    name: 'purple',
                    value: '紫色主题',
                },
                {
                    name: 'green',
                    value: '绿色主题',
                },
                {
                    name: 'lightblue',
                    value: '清逸天蓝',
                },
                {
                    name: 'taupe',
                    value: '质感之境',
                },
                {
                    name: 'blue',
                    value: '星光夜影',
                },
                {
                    name: 'telecomRed',
                    value: '炽热暖阳',
                },
                {
                    name: 'telecomGreen',
                    value: '幻翠奇旅',
                },
                { name: 'stagePrelude', value: '舞台序曲' },
                { name: 'brocadeDragonSoar', value: '锦绣龙腾' },
                { name: 'whisperingWaves', value: '海浪呢喃' },
                { name: 'nightSkyMirror', value: '夜空之镜' },
                { name: 'dawnMistRising', value: '晨雾初升' },
                { name: 'auroraMiracle', value: '极光奇迹' },
                { name: 'sereneSnowMountain', value: '雪山静谧' },
                { name: 'crystalFrost', value: '晶莹霜华' },
                { name: 'rippleWaters', value: '水波涟漪' },
                { name: 'starryJourney', value: '星辰旅途' },
                { name: 'dragon', value: '龙韵新禧' },
                { name: 'deepdark', value: '墨韵金章' },
            ],
        };
    }

    async step1Submit() {
        this.nextLoading = true;
        this.step2.focusIdx = -1;
        try {
            const { data = {} } = await post('/ai/ai/ppt/gen/outline', {
                title: this.step1.title,
                type: this.step1.type,
                uid: this.uid,
                isCardNote: this.step1.isCardNote,
                writingRecordId: this.step1.writingRecordId
                    ? this.step1.writingRecordId
                    : undefined,
            });
            this.step2.mainTitle = data.mainTitle;
            this.step2.subTitle = data.subTitle;
            this.step2.primarySections = data.primarySections.map((d) => ({
                id: uniqueId('step2'),
                ...d,
            }));

            this.nextLoading = false;
            this.curStep = 1;
        } catch (error) {
            this.nextLoading = false;
            console.error(error);
        }
    }
    async step2Submit() {
        this.nextLoading = true;
        try {
            // const res = await get("/ai/ai/ppt/themeList", {});
            // this.step3.templates = [...res.data];
            this.nextLoading = false;
            this.curStep = 2;
        } catch (error) {
            this.nextLoading = false;
        }
    }

    goLast() {
        this.curStep--;
        if (this.curStep < 0) {
            this.curStep = 0;
        }
    }
    goFinish() {}

    step1Change(a, b) {
        console.log(b);
        const { title, type, isCardNote } = b;
        this.step1.title = title;
        this.step1.type = type;
        this.step1.isCardNote = isCardNote;
        if (title && type && [0, 1].includes(isCardNote)) {
            this.step1.canSubmit = true;
        } else {
            this.step1.canSubmit = false;
        }
    }

    step2Foucs(i) {
        this.step2.focusIdx = i;
    }
    step2DragEnd(result) {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            this.step2.primarySections,
            result.source.index,
            result.destination.index,
        );
        this.step2.primarySections = items;
    }
    step2Del(i) {
        this.step2.primarySections.splice(i, 1);
        this.step2.primarySections = [...this.step2.primarySections];
    }
    step2Add() {
        this.step2.primarySections.push({
            id: uniqueId('step2'),
            title: '',
            secondarySections: [
                {
                    title: '',
                    tertiaryTitles: [''],
                },
            ],
        });
    }

    step2Change(index1, v) {
        this.step2.primarySections[index1].title = v;
        this.step2.primarySections = [...this.step2.primarySections];
    }
    step2ChangeSub(index1, index2, v) {
        this.step2.primarySections[index1].secondarySections[index2].title = v;
        this.step2.primarySections = [...this.step2.primarySections];
    }
    step2DelSub(index1, index2, index3) {
        if (index3 === undefined) {
            this.step2.primarySections[index1].secondarySections.splice(index2, 1);
        } else {
            this.step2.primarySections[index1].secondarySections[index2].tertiaryTitles.splice(
                index3,
                1,
            );
        }
        this.step2.primarySections = [...this.step2.primarySections];
    }
    step2AddSub(index1, index2, index3) {
        if (index3 === undefined) {
            this.step2.primarySections[index1].secondarySections.splice(index2 + 1, 0, {
                id: uniqueId('step2'),
                title: '',
                tertiaryTitles: [''],
            });
        } else {
            this.step2.primarySections[index1].secondarySections[index2].tertiaryTitles.splice(
                index3 + 1,
                0,
                '',
            );
        }

        this.step2.primarySections = [...this.step2.primarySections];
    }
    step2ChangeThird(index1, index2, index3, v) {
        this.step2.primarySections[index1].secondarySections[index2].tertiaryTitles[index3] = v;
        this.step2.primarySections = [...this.step2.primarySections];
    }

    step3Focus(idx) {
        this.step3.focusIdx = idx;
    }

    step3Blur(e) {
        e.stopPropagation();
        this.step3.focusIdx = -1;
    }

    async step3Submit() {
        // const res = await post("/ai/ai/ppt/w", {
        //   uid: this.uid,
        //   pptOutline: {
        //     mainTitle: this.step2.mainTitle,
        //     subTitle: this.step2.subTitle,
        //     primarySections: this.step2.primarySections,
        //   },
        //   pptThemeType: this.step3.templates[this.step3.focusIdx].name,
        // });
        // return res;
    }

    async createPPT() {
        const res = await post('/ai/ai/ppt/w', {
            uid: this.uid,
            pptOutline: {
                mainTitle: this.step2.mainTitle,
                subTitle: this.step2.subTitle,
                primarySections: this.step2.primarySections,
            },
            pptThemeType: this.step3.templates[this.step3.focusIdx].name,
        });
        return res;
    }
}
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
export const newPPTStore = new NewPPTStore();
