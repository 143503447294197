import { observer } from 'mobx-react';
import styles from './index.module.scss';
import { summaryStore } from '../store';
import { Summary } from '../Summary/index';
import { Button, Divider, Modal, notification } from 'antd';
import { SyncOutlined } from '@ant-design/icons';

const Step2 = observer(() => {
    const [modal, contextHolder] = Modal.useModal();
    const [notiApi, notiContextHolder] = notification.useNotification();

    async function genAll() {
        try {
            if (!summaryStore.isCheckConfirm) {
                const config = {
                    title: '提示',
                    content: (
                        <div>平台所生成的全文为范文，仅用作参考，不用作毕业全文、发表刊物等</div>
                    ),
                    okText: '同意',
                };
                await modal.warning(config);
            }
            await summaryStore.getPreview();
            // 滚动到页面顶部
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // 使用平滑滚动效果
            });
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <>
            {contextHolder}
            {notiContextHolder}
            <>
                {/*<div id="summaryHeader" className={styles.sectionTitle}>*/}
                {/*    编辑大纲，生成全文*/}
                {/*</div>*/}
                {/*<div className={styles.sectionDesc}>*/}
                {/*    🔔 点击小节右侧按钮，为该小节增加相应的*/}
                {/*    <span>表格，图，公式，代码段</span>*/}
                {/*    ，对该小节进行<span>增加、删减、修改</span>*/}
                {/*</div>*/}
                <div className={styles.docTitle}>题目：{summaryStore.majorName}</div>
                {/* <div className={styles.docType}>科目：11</div> */}
                <Divider
                    dashed={true}
                    style={{
                        borderColor: '#EEEEF1',
                    }}
                />
                <Summary />
                <Divider
                    dashed={true}
                    style={{
                        borderColor: '#EEEEF1',
                    }}
                />

                <Button
                    shape="round"
                    type="primary"
                    size="large"
                    onClick={genAll}
                    loading={summaryStore.isLoadingPreview}
                    style={{ width: '180px' }}
                >
                    预览
                </Button>
                <Button
                    size="large"
                    type="text"
                    className={styles.regenBtn}
                    onClick={() => summaryStore.getSummary(notiApi)}
                >
                    <SyncOutlined />
                    大纲不满意？重新生成
                </Button>
            </>
        </>
    );
});

export default Step2;
