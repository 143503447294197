import styles from './index.module.scss';
import { observer } from 'mobx-react-lite';
import { Button, Form, Input, Popover, Select, Steps, Switch, Tooltip, Upload } from 'antd';
import { reduceStore } from './store';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';
import { useEffect } from 'react';
const { Dragger } = Upload;

export const Reduce = observer(function (props) {
    const nav = useNavigate();
    const [qs] = useSearchParams();

    useEffect(() => {
        const orderNo = qs.get('o') || '';
        if (orderNo) {
            reduceStore.init({ orderNo });
        } else {
            reduceStore.init();
        }
        return () => {
            reduceStore.init();
        };
    }, []);
    const step1 = (
        <div>
            <Step1 />
        </div>
    );
    const step2 = (
        <div>
            <Step2 />
        </div>
    );
    const step3 = (
        <div>
            <Step3 />
        </div>
    );

    // const nowStep = [step1, step2, step3][reduceStore.curStep];
    const nowStep = [step1, step2, step3][1];
    return (
        <div className={styles.reduce}>
            <div className={styles.new}>
                {/* <h1>智能降重/去AI痕迹</h1>
        <h3>按照步骤轻松对全文进行去重</h3> */}
                <div className={styles.step}>
                    {/*<div className={styles.progress}>*/}
                    {/*  <Steps*/}
                    {/*    current={reduceStore.curStep}*/}
                    {/*    onChange={reduceStore.onNewStepChange}*/}
                    {/*    className="site-navigation-steps"*/}
                    {/*    items={reduceStore.steps}*/}
                    {/*    direction="horizontal"*/}
                    {/*  />*/}
                    {/*</div>*/}
                    <div className={styles.content}>{nowStep}</div>
                </div>
            </div>
        </div>
    );
});
