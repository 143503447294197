import {
    Button,
    Dropdown,
    Space,
    Table,
    Tooltip,
    Modal,
    message,
    Pagination,
    Empty,
    Popover,
    Carousel,
} from 'antd';
import Input from 'antd/es/input/Input';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import orderDemo from '../../assets/order-demo.jpg';

export const QueryOrderModal = (props) => {
    const [orderNo, setOrderNo] = useState('');
    const [loading, setLoading] = useState(false);

    const onInputChange = (e) => {
        setOrderNo(e.target.value);
    };

    const onÇonfirm = () => {
        console.log('xx', props.confirm);
        props.confirm &&
            props.confirm(orderNo).then((res) => {
                props.handleOk && props.handleOk();
            });
    };
    return (
        <div>
            <div style={{ marginTop: '24px' }}>
                <Input size="large" placeholder="请输入交易" onChange={onInputChange} />
            </div>
            <div>
                <div style={{ marginBottom: '10px', width: '100%' }}>
                    温馨提示：
                    <div>1.为了产出更高质量的文档，预计10分钟左右生成完成</div>
                    <div>
                        2.文件下载流程：支付宝/微信打开账单，找到对应的支付记录，点开详情页复制订单号/交易单号后，查询结果输入编号即可下载对应的文件
                    </div>
                    <div>
                        3.所有全文及文档都应该由本人通过学习后认真撰写，AI范文相关内容仅视为格式参考，不允许抄袭、代写、直接挪用等行为。
                    </div>
                </div>

                <img src={orderDemo} style={{ width: '100%', height: 'auto' }} />
            </div>
            <div
                style={{
                    display: 'flex',
                    marginTop: '24px',
                    justifyContent: 'flex-end',
                }}
            >
                <Button
                    size="large"
                    type="primary"
                    style={{ marginLeft: '20px' }}
                    onClick={onÇonfirm}
                    loading={loading}
                >
                    确定
                </Button>
            </div>
        </div>
    );
};
