import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';
import { Button, Cascader, Checkbox, ConfigProvider, Input, Popover, Space, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import example from '../../../assets/example.jpg';
import halfCircleDot from '../../../assets/half-circle-dot.svg';
import Img1 from '../../../assets/wenxian-1.jpg';
import Img2 from '../../../assets/wenxian-2.jpg';
import { useDevice } from '../../../hooks/useDevice';
import { AUTO_LIGHT_TYPES } from '../const';
import CustomPasteModal from '../CustomPasteModal/index';
import LiteratureSearchModal from '../LiteratureSearchModal/index';
import SelectedLiteratureItem from '../LiteratureSearchModal/SelectedLiteratureItem';
import { summaryStore } from '../store';
import styles from './step1.module.scss';

const Step1 = observer(({ generateOutline }) => {
    const useLiteratureSearchModal = useModal(LiteratureSearchModal);
    const useCustomPasteModal = useModal(CustomPasteModal);

    const { isMobile } = useDevice();

    return (
        <>
            <div className={styles.input}>
                <Space.Compact className={'w-full'}>
                    <ConfigProvider
                        theme={{
                            components: {
                                Cascader: {
                                    controlItemWidth: 144,
                                },
                            },
                        }}
                    >
                        <Cascader
                            size="large"
                            options={summaryStore.categories}
                            placeholder="选择科目"
                            showSearch
                            fieldNames={{
                                label: 'sname',
                                value: 'scode',
                                children: 'children',
                            }}
                            style={{
                                width: 160,
                                height: '56px',
                            }}
                        />
                    </ConfigProvider>

                    <Input
                        placeholder="请输入文章题目"
                        size="large"
                        style={{ width: '100%' }}
                        value={summaryStore.majorName}
                        onChange={(e) => summaryStore.changeMajorName(e.target.value)}
                        disabled={summaryStore.isLoadingSummary}
                    />
                </Space.Compact>
            </div>
            {/*额外服务*/}
            {/* 在文献综述和开题报告时不显示 */}
            {summaryStore.docType !== 'PROPOSAL_REPORT' &&
            summaryStore.docType !== 'LITERATURE_REVIEW' ? (
                <div
                    className={styles.wenxianCheck}
                    style={isMobile ? { flexDirection: 'column' } : {}}
                >
                    <div className={'flex w-full flex-col md:flex-row md:items-center'}>
                        <div className={'flex flex-nowrap'}>
                            <Tooltip
                                color="#fff"
                                title={
                                    <span
                                        style={{
                                            color: '#18191c',
                                            width: '250px',
                                        }}
                                    >
                                        您可根据全文需求需要勾选相应的
                                        <hl>表，图，公式，代码</hl>
                                        <br />
                                        在生成大纲后仍可继续进行<hl>添加或删减</hl>
                                        <br />
                                        点亮后，小灵会自动判断勾选内容需要的具体需求，如图所示：
                                        <img
                                            src={example}
                                            style={{
                                                color: '#18191c',
                                                width: '240px',
                                            }}
                                            alt={''}
                                        />
                                    </span>
                                }
                            >
                                <QuestionCircleOutlined
                                    color="#999999"
                                    className={styles.summaryIcon}
                                />
                            </Tooltip>
                            <div className={'ml-1 mr-3 text-textGray'}>全文内容需要包含</div>
                        </div>
                        <div className="mt-1 flex flex-1 gap-2 md:mt-0">
                            {AUTO_LIGHT_TYPES.map(({ value, label, icon, activeIcon }) => {
                                const isActive = summaryStore.autoLightTypes.includes(value);

                                return (
                                    <button
                                        key={value}
                                        onClick={() => {
                                            const newTypes = summaryStore.autoLightTypes.includes(
                                                value,
                                            )
                                                ? summaryStore.autoLightTypes.filter(
                                                      (type) => type !== value,
                                                  )
                                                : [...summaryStore.autoLightTypes, value];
                                            summaryStore.onAutoLightChange(newTypes);
                                        }}
                                        disabled={summaryStore.isLoadingSummary}
                                        className={`flex items-center gap-1 rounded-md px-2 py-1 md:px-5 ${
                                            isActive ? 'bg-[#F4EEFF]' : 'bg-[#F8FBFF]'
                                        }`}
                                    >
                                        <img
                                            src={isActive ? activeIcon : icon}
                                            alt={label}
                                            className="h-4 w-4"
                                        />
                                        <span
                                            className={`text-sm ${
                                                isActive ? 'text-primary' : 'text-[#414C6F]'
                                            }`}
                                        >
                                            {label}
                                        </span>
                                    </button>
                                );
                            })}
                        </div>
                    </div>
                </div>
            ) : null}
            {/*参考文献标题*/}
            <div className={'mt-7 flex w-full items-center justify-between md:mt-9'}>
                <div className={'flex items-center'}>
                    <img src={halfCircleDot} className={'mr-1.5 h-2.5 w-0.5'} alt="" />
                    <span className={'text-base font-medium text-[#3D3D3D]'}>参考文献</span>
                    <Popover
                        placement="rightTop"
                        trigger={isMobile ? 'click' : 'hover'}
                        content={
                            <div className={'w-[500px] max-w-[90vw]'}>
                                <img className={'mx-auto my-1 w-full'} src={Img1} />
                                <img className={'mx-auto my-1 w-full'} src={Img2} />
                            </div>
                        }
                        title="文献获取指引"
                    >
                        <QuestionCircleOutlined className={'ml-2.5 mr-1'} />
                        <span className={'cursor-default text-xs text-textGray'}>说明</span>
                    </Popover>
                </div>
                {summaryStore.customReference.customReferenceDocument.length ? (
                    <Btns
                        useLiteratureSearchModal={useLiteratureSearchModal}
                        useCustomPasteModal={useCustomPasteModal}
                    />
                ) : null}
            </div>
            {/*文献选择区域*/}
            <div className={'w-full'}>
                <div
                    className={
                        'mt-3 flex w-full flex-col items-center rounded-lg border border-[#EEEEF1] p-3'
                    }
                >
                    <div className={styles.tipTitle}>
                        <InfoCircleOutlined
                            style={{
                                color: 'var(--colorPrimary)',
                            }}
                        />
                        <span className={'text-xs text-primary'}>
                            参考文献建议 10 条以上，请选择与你全文相关文献，切勿乱选/乱传参考文献
                        </span>
                    </div>

                    {summaryStore.customReference.customReferenceDocument.length ? (
                        <div className={'flex w-full flex-col gap-3'}>
                            {summaryStore.customReference.customReferenceDocument.map((it) => {
                                return (
                                    <SelectedLiteratureItem
                                        key={it.id}
                                        item={it}
                                        onClose={() => {
                                            summaryStore.deletesCustomReferenceDocument(it.id);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <Btns
                            useLiteratureSearchModal={useLiteratureSearchModal}
                            useCustomPasteModal={useCustomPasteModal}
                        />
                    )}
                </div>
            </div>
            <Button
                className={styles.geneBtn}
                size="large"
                shape="round"
                type="primary"
                onClick={() => {
                    generateOutline();
                }}
                loading={summaryStore.isLoadingSummary}
            >
                生成大纲
            </Button>
            {/*免责区域*/}
            <div className={'mt-4 flex justify-center'}>
                <Checkbox
                    checked={summaryStore.isCheckConfirm}
                    onChange={(e) => {
                        summaryStore.setIsCheckConfirm(e.target.checked);
                    }}
                ></Checkbox>
                <div
                    className={'ml-2 flex cursor-pointer items-center text-[#414C6F]'}
                    onClick={() => {
                        summaryStore.setIsCheckConfirm(!summaryStore.isCheckConfirm);
                    }}
                >
                    我已阅读并同意{' '}
                    <a
                        style={{ color: '#666' }}
                        href={`${window.location.protocol}//${window.location.host}/agreement`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        《服务条款》
                    </a>
                    ：生成的全文范文仅用于参考，不作为毕业、发表使用
                </div>
            </div>
        </>
    );
});

const Btns = observer(({ useLiteratureSearchModal, useCustomPasteModal }) => {
    return (
        <div className={'flex items-center justify-end'}>
            <Space>
                <div className="flex h-[32px] w-[100px] items-center justify-center gap-2 rounded-md border border-solid border-primary px-2">
                    <Checkbox
                        checked={summaryStore.isReref}
                        onChange={(e) => {
                            summaryStore.setisReref(e.target.checked);
                        }}
                    />
                    <span
                        className="cursor-pointer select-none text-sm text-primary"
                        onClick={() => summaryStore.setisReref(!summaryStore.isReref)}
                    >
                        推荐文献
                    </span>
                </div>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#34BC43',
                        },
                    }}
                >
                    <Button
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => {
                            useLiteratureSearchModal
                                .show({
                                    initialSearchValue: summaryStore.majorName,
                                })
                                .then((items) => {
                                    summaryStore.addCustomReferenceDocument(items);
                                })
                                .catch(() => {});
                        }}
                    >
                        选择文献
                    </Button>
                </ConfigProvider>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#1E84FF',
                        },
                    }}
                >
                    <Button
                        color={'primary'}
                        variant={'outlined'}
                        onClick={() => {
                            useCustomPasteModal
                                .show({})
                                .then((items) => {
                                    summaryStore.addCustomReferenceDocument(items);
                                })
                                .catch(() => {});
                        }}
                    >
                        手动添加
                    </Button>
                </ConfigProvider>
            </Space>
        </div>
    );
});

export default Step1;
