import { Modal, message } from 'antd';
import NiceModal from '@ebay/nice-modal-react';
import { DownloadOrderModal } from '../../../../components/Order/DownloadOrderModal';
import { useState } from 'react';

export const DownloadPaperModal = NiceModal.create(({ orderNo, queryType, afterClose }) => {
    const modal = NiceModal.useModal();
    const [downloading, setDownloading] = useState(false);

    const handleCancel = () => {
        modal.hide();
    };

    return (
        <Modal
            title="下载全文"
            open={modal.visible}
            onCancel={handleCancel}
            afterClose={afterClose}
            footer={null}
            destroyOnClose
            maskClosable={!downloading}
            closable={!downloading}
            width={600} // 可根据需要调整宽度
        >
            <DownloadOrderModal orderNo={orderNo} queryType={queryType} />
        </Modal>
    );
});
