import styles from './index.module.scss';
import { Button, Divider, Input, Spin, message } from 'antd';
import { useState, useCallback } from 'react';
import { get } from '../../axios/axios';
import orderDemo from '../../assets/order-desc.png';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EditOutlined, UnlockOutlined, DownloadOutlined } from '@ant-design/icons';
import { useModal } from '@ebay/nice-modal-react';
import PPTpayModal from '../../Article/PPT/PPTpayModal';
import documentIcon from '../../assets/document-icon.png';

// 订单查询弹窗组件
export const OrderModal = ({ editBtnText = '编辑', handleOk }) => {
    // 订单号输入框的值
    const [orderNo, setOrderNo] = useState('');
    // 加载状态
    const [loading, setLoading] = useState(false);
    // 订单信息
    const [orderInfo, setOrderInfo] = useState();
    // 单章订单号，如果有值表示当前是解锁单章的订单
    const [singleOrderNo, setSingleOrderNo] = useState(null);
    // 支付弹窗的 hook
    const usePPTProModal = useModal(PPTpayModal);

    // 处理输入框值变化
    function onChange(e) {
        setOrderNo(e.target.value);
        // 清空之前的订单信息和单章订单状态
        setOrderInfo(undefined);
        setSingleOrderNo(null);
    }

    // 判断这个downloadUrl就可以 有-的都编辑不了 两个ML的也编辑不了
    const isEditable = useCallback((downloadUrl) => {
        if (!downloadUrl) return false;
        return !downloadUrl.includes('-') && downloadUrl.toUpperCase().split('ML').length < 3;
    }, []);

    // 解锁全章的处理函数
    async function unlockAll(orderNo) {
        usePPTProModal
            .show({
                title: '解锁全章',
                majorName: orderInfo.paperTitle,
                orderNo,
                price: orderInfo.orderPrice,
            })
            .then(async () => {
                // 支付成功后重新获取订单信息
                await queryOrder();
                // 清除单章订单状态，因为已经升级为全章订单
                setSingleOrderNo(null);
                message.success('已成功解锁全章');
            })
            .catch((error) => {
                console.error('解锁全章失败:', error);
                message.error('解锁全章失败，请重试');
            });
    }

    // 查询订单信息
    async function queryOrder() {
        // 校验订单号是否为空
        if (!orderNo.trim()) {
            message.warning('请输入订单号');
            return;
        }

        try {
            setLoading(true);
            setOrderInfo(undefined);
            const orderInfo = await get('/ai/paper/query', { orderNo: orderNo });

            // 校验是否获取到订单信息
            if (!orderInfo.data) {
                message.error('未找到订单信息');
                return;
            }

            setOrderInfo(orderInfo.data);
            // 设置单章订单状态
            setSingleOrderNo(orderInfo.data?.orderNo);

            // 检查文档生成进度
            const completedDocs = orderInfo.data.list.filter((i) => i.downloadUrl).length;
            const totalDocs = orderInfo.data.list.length;

            // 如果还有文档未生成完成，显示进度提示
            if (completedDocs < totalDocs) {
                message.info(`正在生成中，预计需要30分钟全部完成`);
            }
        } catch (error) {
            console.error('查询订单失败:', error);
            message.error('查询订单失败，请检查订单号是否正确');
        } finally {
            setLoading(false);
        }
    }

    // 下载文件
    function download(url) {
        if (!url) {
            message.warning('小灵正在马不停蹄生成中，请您稍等');
            return;
        }
        window.open(url, '_blank');
    }

    // 路由导航 hook
    const nav = useNavigate();
    // 跳转到编辑页面
    const edit = (downloadUrl) => {
        handleOk();
        const orderNo = downloadUrl.split('/').pop();
        nav(`/editor?orderNo=${orderNo}`);
    };

    // 判断是否为全章订单
    function isFullChapterOrder() {
        return orderInfo && !singleOrderNo;
    }

    return (
        <div className={styles.orderModal}>
            {/* 订单号输入区域 */}
            <div className={'w-full'}>
                <Space.Compact className={'w-full'}>
                    <Input value={orderNo} onChange={onChange} placeholder="请输入交易单号" />
                    <Button loading={loading} type="primary" onClick={queryOrder}>
                        查询
                    </Button>
                </Space.Compact>
            </div>

            <Spin spinning={loading}>
                {orderInfo ? (
                    // 订单信息展示区域
                    <div className={styles.content}>
                        {/* 订单基本信息 */}
                        <div className={styles.header}>
                            <div>订单号：{orderNo}</div>
                            <div>{orderInfo.createTime}</div>
                        </div>
                        {orderInfo.list.map((l, idx) => {
                            return (
                                <div className={styles.paperInfo} key={idx}>
                                    <img src={documentIcon} alt="" className="h-16 w-16" />
                                    <div className="flex-1">
                                        <div className={styles.title}>{orderInfo.paperTitle}</div>
                                        {/* 章节列表 */}
                                        <div className={styles.list}>
                                            <div className={styles.listRow} key={idx}>
                                                <div className={`${styles.listTitle} line-clamp-2`}>
                                                    {l.title}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Space className="flex items-center">
                                        {isEditable(l.downloadUrl) && !singleOrderNo && (
                                            <Button
                                                color="primary"
                                                variant="outlined"
                                                icon={<EditOutlined />}
                                                onClick={() => edit(l.downloadUrl)}
                                            >
                                                {editBtnText}
                                            </Button>
                                        )}
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            icon={<DownloadOutlined />}
                                            onClick={() => download(l.downloadUrl)}
                                        >
                                            下载
                                        </Button>
                                    </Space>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    // 使用说明区域
                    <div>
                        <div className={'mt-3'}>
                            <div className="text-[#FFA346]">温馨提示：</div>
                            <div>1.为了产出更高质量的文档，预计10分钟左右生成完成</div>
                            <div>
                                2.文件下载流程：支付宝/微信打开账单，找到对应的支付记录，点开详情页复制订单号/交易单号后，查询结果输入编号即可下载对应的文件
                            </div>
                            <div>
                                3.所有全文及文档都应该由本人通过学习后认真撰写，AI范文相关内容仅视为格式参考，不允许抄袭、代写、直接挪用等行为。
                            </div>
                            <img
                                src={orderDemo}
                                style={{ width: '100%', height: '288px', marginTop: '12px' }}
                            />
                        </div>
                    </div>
                )}
            </Spin>

            {/* 底部操作按钮区域 */}
            <div className={styles.footer}>
                <Space>
                    {orderInfo && orderNo ? (
                        <>
                            {/* 仅单章订单显示解锁全章按钮 */}
                            {singleOrderNo && (
                                <Button
                                    type="primary"
                                    onClick={() => unlockAll(singleOrderNo)}
                                    icon={<UnlockOutlined />}
                                >
                                    解锁全文
                                </Button>
                            )}
                        </>
                    ) : null}
                </Space>
            </div>
        </div>
    );
};
