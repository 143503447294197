import { Alert, Button, Layout, message, Modal, QRCode, Space, Spin, Tree, Radio } from 'antd';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import error from '../../../assets/error.png';
import success from '../../../assets/success.png';
import { baseURL, get, post } from '../../../axios/axios';
import styles from './index.module.scss';

import {
    LeftOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    NotificationOutlined,
    RightOutlined,
} from '@ant-design/icons';
import useCountdown from '@bradgarropy/use-countdown';
import { Carousel, Checkbox } from 'antd';
import { globalData } from '../../../Service/utils';
import { summaryStore } from '../store';

import { useModal } from '@ebay/nice-modal-react';
import word from '../../../assets/slider-word.png';
import zy from '../../../assets/slider-zy.png';
import ckwx from '../../../assets/slider-ckwx.png';
import { useDevice } from '../../../hooks/useDevice';
import { loginInstance } from '../../../Service/login';
import CountdownProgress from './components/CountdownProgress';
import { DownloadPaperModal } from './components/DownloadPaperModal';
import RedeemModal from './components/RedeemModal';
import SummaryDrawer from './components/SummaryDrawer';
import useAbstract from './hooks/useAbstract';
import SelectUnlockChapter from './components/SelectUnlockChapter';
const { Sider, Content } = Layout;

const auth = loginInstance.getHeaders();
const authHeaders = auth ? { Authorization: auth } : {};

export const Step3 = observer(() => {
    const useRedeemModal = useModal(RedeemModal);
    const useSummaryDrawer = useModal(SummaryDrawer);
    const useDownloadPaperModal = useModal(DownloadPaperModal);

    const [paper, setPaper] = useState(summaryStore.paperInfo || {});
    const [showPayModal, setShowPayModal] = useState(false);
    const [timerId, setTimerId] = useState('');
    const [payResult, setPayResult] = useState(false); // 支付结果
    const payInfoRef = useRef({});
    const [showPayResult, setShowPayResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [demoList, setDemoList] = useState([]);
    const counts = summaryStore.getSelectTypeCount();
    const [payType, setPayType] = useState(1); // 1:微信 2:支付宝
    const [alipayUrl, setAlipayUrl] = useState('');

    // 修改大纲弹窗逻辑
    const updateOutlineCallback = useCallback(async () => {
        await summaryStore.updateOutline();
        message.success('修改成功');
    }, []);
    const showDrawer = useCallback((e) => {
        e.stopPropagation();
        useSummaryDrawer.show({
            onConfirm: updateOutlineCallback,
        });
    }, []);

    // 我有兑换码弹窗
    const showCodeModal = async (e) => {
        e.stopPropagation();
        await useRedeemModal
            .show({
                orderNo: paper.orderNo,
            })
            .then(() => {
                setPayResult(true);
                setTimeout(() => {
                    countdownProgressRef.current?.startCountdown();
                }, 1000);
            })
            .catch(() => {
                window.clearTimeout(timerId);
                setShowPayResult(false);
                setShowPayModal(false);
            });
    };

    // 中文摘要流
    const {
        abstracts: cnAbstracts,
        abstractsCompleted: cnAbstractsCompleted,
        streamLoading: cnStreamLoading,
        streamRequest: cnStreamRequest,
        reset: cnReset,
    } = useAbstract({
        baseURL,
        authHeaders,
        url: 'ai/ai/paper/gen/c/abstract',
    });
    useEffect(() => {
        cnStreamRequest({
            orderNo: paper.orderNo,
        });
    }, []);

    // 英文摘要流
    const {
        abstracts: enAbstracts,
        abstractsCompleted: enAbstractsCompleted,
        streamLoading: enStreamLoading,
        streamRequest: enStreamRequest,
        reset: enReset,
    } = useAbstract({
        baseURL,
        authHeaders,
        url: 'ai/ai/paper/gen/e/abstract',
    });

    useEffect(() => {
        if (cnAbstractsCompleted) {
            console.log('cnAbstracts', cnAbstracts);
            enStreamRequest({
                orderNo: paper.orderNo,
            });
        }
    }, [cnAbstractsCompleted]);

    // 处理继续预览
    const handleContinuePreview = useCallback(async () => {
        await summaryStore.getFinalPriview();
    }, [summaryStore]);

    const countdownProgressRef = useRef(null);
    const [generateFinish, setGenerateFinish] = useState(false);

    const onCancel = () => {
        window.clearTimeout(timerId);
        payInfoRef.current = {};
        setShowPayResult(false);
        setShowPayModal(false);
        setPayType(1);
        setAlipayUrl('');
        globalData.destroy = 1;
    };

    // 错误处理
    const handleError = useCallback((error) => {
        message.error(error.message || '操作失败');
    }, []);

    const submitPay = async (newPayType) => {
        try {
            if (loading) {
                return;
            }
            setLoading(true);
            const client = 'PC';
            const res = await post('/member/payment/pay', {
                orderNo: paper.orderNo,
                client: client,
                payType: newPayType || payType,
            });

            if (client === 'H5') {
                window.location.href = res.data.mwebUrl;
            } else {
                globalData.destroy = 0;
                if (newPayType === 1 && res.data.codeUrl) {
                    payInfoRef.current = {
                        ...(res.data || {}),
                    };
                } else if (newPayType === 2 && res.data.form) {
                    setAlipayUrl(res.data.form);
                }
                if (!showPayModal) {
                    setPayType(1); // 只在首次打开时设置为微信支付
                    setShowPayModal(true);
                }
                checkPay({ force: true });
            }
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const toRePay = async () => {
        setShowPayResult(false);
        const res = await post('/member/payment/pay', {
            orderNo: paper.orderNo,
            client: 'PC',
            payType: payType,
        });

        if (payType === 1 && res.data.codeUrl) {
            payInfoRef.current = res.data || {};
        } else if (payType === 2 && res.data.form) {
            setAlipayUrl(res.data.form);
        }
    };

    const checkPay = async (options = {}) => {
        if (timerId) {
            window.clearTimeout(timerId);
        }
        if (globalData.destroy) {
            return;
        }

        try {
            const res = await get('/member/payment/pay/result/' + paper.orderNo);
            if (res && res.data && res.data.code === 0) {
                setShowPayResult(true);
                setPayResult(res.data.code === 0);

                setTimeout(() => {
                    onCancel();
                    countdownProgressRef.current?.startCountdown();
                }, 1000);
            } else {
                const id = window.setTimeout(() => {
                    checkPay();
                }, 1000);
                setTimerId(id);
            }
        } catch (err) {
            console.error(err);
        }
    };

    // 处理支付
    const handlePay = useCallback(
        async (newPayType) => {
            try {
                await submitPay(newPayType);
            } catch (error) {
                handleError(error);
            }
        },
        [submitPay, handleError],
    );

    const useSelectUnlockChapter = useModal(SelectUnlockChapter);
    // 9.9解锁任意单章正文
    const unlockAnyChapter = async () => {
        useSelectUnlockChapter
            .show({
                orderNo: paper.orderNo,
            })
            .then(() => {
                summaryStore.getPriceAfterAddServices();
            });
    };

    // 进行全文下载
    const showOrderModal = () => {
        useDownloadPaperModal.show({
            orderNo: paper.orderNo,
            queryType: 'query',
        });
    };

    function getHtmlContent(text) {
        // 匹配 \textsuperscript{[数字]} 的模式
        const regex = /\\ccccc\{\[(\d+)\]\}/g;
        // 使用 \$1 来引用第一个括号中捕获的内容（即数字），并在替换文本中保留方括号
        return text.replace(regex, '<span>[$1]</span>');
    }

    const countdown = useCountdown({
        minutes: 0,
        seconds: 30 * 60,
        format: 'mm:ss',
        autoStart: false,
        onCompleted: () => console.log('onCompleted'),
    });

    useEffect(() => {
        if (showPayModal) {
            countdown.start();
        }
    }, [showPayModal, countdown]);

    useEffect(() => {
        (async () => {
            await summaryStore.getCatalogueData();
        })();
    }, []);

    const siderStyle = {
        overflow: 'auto',
        height: 'calc(100vh - 56px)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        scrollbarWidth: 'thin',
        scrollbarGutter: 'stable',
        backgroundColor: '#fff',
    };

    const [collapsed, setCollapsed] = useState(false);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };
    // 展开/收起按钮样式
    const toggleButtonStyle = {
        position: 'absolute',
        right: '-16px',
        top: '50%',
        transform: 'translateY(-50%)',
        width: '32px',
        height: '32px',
        backgroundColor: '#fff',
        border: '1px solid #EEEEF1',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        zIndex: 100,
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
    };

    const { isMobile } = useDevice();

    const [mobileCatalogueOpen, setMobileCatalogueOpen] = useState(false);
    const Catalogue = () => {
        return (
            <Tree
                defaultExpandAll={summaryStore.catalogueDataLoaded}
                showLine={false}
                showIcon={false}
                treeData={summaryStore.catalogueData}
            />
        );
    };

    const TOTAL_TIME = 40 * 60;
    const [timeLeft, setTimeLeft] = useState(TOTAL_TIME);
    const [isRunning, setIsRunning] = useState(false);

    const progress = ((TOTAL_TIME - timeLeft) / TOTAL_TIME) * 100;

    useEffect(() => {
        let timer;
        if (isRunning && timeLeft > 0) {
            timer = setInterval(() => {
                setTimeLeft((prev) => {
                    if (prev <= 1) {
                        clearInterval(timer);
                        setIsRunning(false);
                        setGenerateFinish(true);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }

        return () => timer && clearInterval(timer);
    }, [isRunning, timeLeft]);

    useEffect(() => {
        if (payResult && !generateFinish && !isRunning) {
            setTimeLeft(TOTAL_TIME);
            setTimeout(() => {
                setIsRunning(true);
            }, 1000);
        }
    }, [payResult, generateFinish]);

    const ExtraContent = () => {
        return (
            <>
                <div className="font-sans">
                    {/* 标题 */}
                    <h1 className="mb-3 text-[16px] text-[#192038]">您将获得</h1>

                    {/* 正文项目 - 蓝色边框 */}
                    <div className="mb-3 flex items-center rounded-lg border border-[#EEEEF1] p-4">
                        <div className="mr-3 h-6 w-6">
                            <img src={word} alt="" />
                        </div>
                        <div className="flex flex-1 items-center justify-between">
                            <span className="text-[14px] text-[#192038]">无限AI改稿</span>
                        </div>
                    </div>

                    {/* 中英文摘要 - 灰色边框 */}
                    <div className="mb-3 flex items-center rounded-lg border border-[#EEEEF1] p-4">
                        <div className="mr-3 h-6 w-6">
                            <img src={zy} alt="" />
                        </div>
                        <div className="flex flex-1 items-center justify-between">
                            <span className="text-[14px] text-[#192038]">一键降重</span>
                            <span className="text-[14px] text-[#192038] opacity-50"></span>
                        </div>
                    </div>

                    {/* 中英参考文献 - 灰色边框 */}
                    <div className="mb-3 flex items-center rounded-lg border border-[#EEEEF1] p-4">
                        <div className="mr-3 h-6 w-6">
                            <img src={ckwx} alt="" />
                        </div>
                        <div className="flex flex-1 items-center justify-between">
                            <span className="text-[14px] text-[#192038]">正文AI插入图表</span>
                            {/* <span className="text-[14px] text-[#192038] opacity-50">× 30</span> */}
                        </div>
                    </div>

                    {/* 致谢模板 - 灰色边框 */}
                    {/*<div className="mb-3 flex items-center rounded-lg border border-[#EEEEF1] p-4">*/}
                    {/*    <div className="mr-3 h-6 w-6">*/}
                    {/*        <img src={zxmb} alt="" />*/}
                    {/*    </div>*/}
                    {/*    <div className="flex flex-1 items-center justify-between">*/}
                    {/*        <span className="text-[14px] text-[#192038]">致谢模板</span>*/}
                    {/*        <span className="text-[14px] text-[#192038] opacity-50">× 5</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
                {summaryStore.serviceList &&
                summaryStore.serviceList.length > 0 &&
                enAbstractsCompleted &&
                cnAbstractsCompleted &&
                summaryStore.hasPreviewEnd ? (
                    <Spin
                        spinning={
                            summaryStore.isExtraloading ||
                            enStreamLoading ||
                            cnStreamLoading ||
                            summaryStore.nextPreviewLoading
                        }
                    >
                        <div className={styles.title}>附加服务</div>
                        <div className={styles.extraBox}>
                            {summaryStore.serviceList.map((service, idx) => {
                                return (
                                    <div
                                        key={idx + service.id}
                                        className={`${styles.extra} ${
                                            service.checked && styles.active
                                        }`}
                                    >
                                        <div style={{ position: 'relative' }}>
                                            <div className={styles.name}>{service.sname}</div>
                                            <div className={styles.desc}>
                                                <span className={styles.price}>
                                                    {service.price}元
                                                </span>
                                                <span className={styles.origin}>
                                                    {service.price2}元
                                                </span>
                                            </div>
                                        </div>
                                        <Checkbox
                                            style={{ position: 'relative' }}
                                            checked={service.checked}
                                            onChange={(e) =>
                                                summaryStore.onServiceCheck(e.target.checked, idx)
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </Spin>
                ) : null}
                <div className="relative">
                    {/* 客服二维码 */}
                    <div
                        className={`flex w-full flex-col items-center transition-all duration-300 ${
                            payResult && generateFinish
                                ? 'visible opacity-100'
                                : 'invisible absolute left-0 top-0 opacity-0'
                        }`}
                    >
                        <img
                            width="120"
                            height="120"
                            src="https://multure.oss-cn-hangzhou.aliyuncs.com/kf.png"
                        />
                        <div className={'mt-2 pb-3 text-sm text-[#414C6F]'}>
                            全文还未生成？扫码联系客服
                        </div>
                    </div>

                    {/* 倒计时进度条 */}
                    <div
                        className={`transition-all duration-300 ${
                            payResult && !generateFinish
                                ? 'visible opacity-100'
                                : 'invisible absolute left-0 top-0 opacity-0'
                        }`}
                    >
                        <CountdownProgress timeLeft={timeLeft} progress={progress} />
                    </div>
                </div>
            </>
        );
    };

    const isShowExtra =
        summaryStore.docType !== 'PROPOSAL_REPORT' && summaryStore.docType !== 'LITERATURE_REVIEW';

    return (
        <>
            <Layout
                style={{
                    backgroundColor: '#fff',
                    marginTop: 12,
                }}
            >
                {isMobile ? null : (
                    <Sider
                        width={'18%'}
                        collapsible
                        collapsed={collapsed}
                        trigger={null}
                        collapsedWidth={0}
                        style={{
                            ...siderStyle,
                            left: 0,
                            top: 56,
                            padding: 12,
                            borderRight: '1px solid #EEEEF1',
                            overflow: 'initial',
                            '&::WebkitScrollbar': {
                                width: '0px',
                            },
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                        }}
                    >
                        <div className={'h-full overflow-y-scroll'}>
                            {summaryStore.catalogueDataLoaded && !collapsed ? <Catalogue /> : null}
                            {/* 展开/收起按钮 */}
                            <div style={toggleButtonStyle} onClick={toggleCollapsed}>
                                {collapsed ? <RightOutlined /> : <LeftOutlined />}
                            </div>
                        </div>
                    </Sider>
                )}
                <Content>
                    <div className={styles.result}>
                        <div className={styles.resultPage}>
                            {isMobile ? (
                                <div
                                    className={'flex items-center'}
                                    onClick={() => {
                                        setMobileCatalogueOpen(!mobileCatalogueOpen);
                                    }}
                                >
                                    {mobileCatalogueOpen ? (
                                        <MenuUnfoldOutlined />
                                    ) : (
                                        <MenuFoldOutlined />
                                    )}
                                    <span>目录</span>
                                </div>
                            ) : null}
                            {/* 标题部分 */}
                            <div className={styles.pageTitle}>{paper?.data?.title}</div>

                            {/* 中文摘要 */}
                            <div>
                                <div className={styles.chapterTitle}>中文摘要</div>
                                <div
                                    className={styles.content}
                                    dangerouslySetInnerHTML={{
                                        __html: getHtmlContent(cnAbstracts),
                                    }}
                                />
                            </div>

                            {/* 英文摘要 */}
                            {cnAbstractsCompleted && (
                                <div>
                                    <div className={styles.chapterTitle}>Abstract</div>
                                    <div
                                        className={styles.content}
                                        dangerouslySetInnerHTML={{
                                            __html: getHtmlContent(enAbstracts),
                                        }}
                                    />
                                </div>
                            )}

                            {/* 继续预览内容 */}
                            {summaryStore.hasPreviewEnd && enAbstractsCompleted && (
                                <>
                                    {/* <div className={styles.pageTitle}> */}
                                    <div className={styles.chapterTitle}>
                                        {paper?.list?.[0]?.chapter}
                                    </div>
                                    <div>
                                        <div
                                            className={styles.chapterTitle}
                                            style={{
                                                textAlign: 'left',
                                            }}
                                        >
                                            {paper?.list?.[0]?.section}
                                        </div>
                                        {/*<div className={styles.content}>*/}
                                        {/*    {paper?.list?.[0]?.content}*/}
                                        {/*</div>*/}
                                        <div
                                            className={styles.content}
                                            dangerouslySetInnerHTML={{
                                                __html: paper?.list?.[0]?.content?.replace(
                                                    /\[\[citation:(\d+)\]\]/g,
                                                    (match, num) => `<sup>[${num}]</sup>`,
                                                ),
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            {/* 加载提示 */}
                            {(summaryStore.nextPreviewLoading ||
                                cnStreamLoading ||
                                enStreamLoading) && (
                                <Spin tip="生成中" style={{ margin: '0 auto' }}>
                                    <div
                                        style={{
                                            height: '160px',
                                            width: '100%',
                                        }}
                                    />
                                </Spin>
                            )}

                            {/* 支付/下载区域 */}
                            <div className={styles.payButtonBox}>
                                <div className={styles.payMask} />
                                <div>
                                    {payResult ? (
                                        // 已支付状态
                                        <div className="flex justify-center gap-2">
                                            <Button
                                                onClick={showOrderModal}
                                                type="primary"
                                                size="large"
                                                shape="round"
                                            >
                                                下载全文
                                            </Button>
                                            {/*<Button*/}
                                            {/*    onClick={goStep4}*/}
                                            {/*    type="primary"*/}
                                            {/*    size="large"*/}
                                            {/*    shape="round"*/}
                                            {/*>*/}
                                            {/*    前往编辑*/}
                                            {/*</Button>*/}
                                        </div>
                                    ) : (
                                        // 未支付状态
                                        cnAbstractsCompleted &&
                                        enAbstractsCompleted && (
                                            <div className="flex flex-col">
                                                <div className="flex justify-center">
                                                    {/* 继续预览按钮 */}
                                                    {!summaryStore.hasPreviewEnd && (
                                                        <Button
                                                            loading={
                                                                summaryStore.nextPreviewLoading
                                                            }
                                                            size="large"
                                                            shape="round"
                                                            onClick={handleContinuePreview}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                marginRight: '10px',
                                                                width: '160px',
                                                            }}
                                                        >
                                                            ￥0继续预览
                                                        </Button>
                                                    )}
                                                    {/* 支付按钮，预览完成时显示 */}
                                                    {summaryStore.hasPreviewEnd &&
                                                    enAbstractsCompleted &&
                                                    cnAbstractsCompleted ? (
                                                        <Space.Compact
                                                            direction={
                                                                isMobile ? 'vertical' : 'horizontal'
                                                            }
                                                        >
                                                            <Button
                                                                type="primary"
                                                                loading={
                                                                    loading ||
                                                                    summaryStore.isExtraloading
                                                                }
                                                                onClick={() => {
                                                                    handlePay(1);
                                                                }}
                                                                size="large"
                                                                shape="round"
                                                                style={{
                                                                    height: '56px',
                                                                }}
                                                            >
                                                                <div>解锁全篇</div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            color: '#FFE924',
                                                                        }}
                                                                    >
                                                                        ￥
                                                                        {
                                                                            summaryStore.paperInfo
                                                                                ?.prices
                                                                                ?.totalDiscountedPrice
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            fontSize: '12px',
                                                                            textDecoration:
                                                                                'line-through',
                                                                            color: 'rgba(255,255,255,0.65)',
                                                                        }}
                                                                    >
                                                                        ￥
                                                                        {
                                                                            summaryStore.paperInfo
                                                                                ?.prices
                                                                                ?.totalOriginalPrice
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Button>
                                                            <Button
                                                                type="primary"
                                                                loading={
                                                                    loading ||
                                                                    summaryStore.isExtraloading
                                                                }
                                                                onClick={unlockAnyChapter}
                                                                size="large"
                                                                shape="round"
                                                                style={{
                                                                    height: '56px',
                                                                }}
                                                            >
                                                                <div>解锁任意单章正文</div>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        alignItems: 'center',
                                                                    }}
                                                                >
                                                                    <div
                                                                        style={{
                                                                            fontSize: '16px',
                                                                            color: '#FFE924',
                                                                        }}
                                                                    >
                                                                        ￥9.90
                                                                    </div>
                                                                    <div
                                                                        style={{
                                                                            fontSize: '12px',
                                                                            textDecoration:
                                                                                'line-through',
                                                                            color: 'rgba(255,255,255,0.65)',
                                                                        }}
                                                                    >
                                                                        ￥{(9.9 * 1.5).toFixed(2)}
                                                                    </div>
                                                                </div>
                                                            </Button>
                                                        </Space.Compact>
                                                    ) : null}
                                                </div>

                                                {/* 格修改提示 */}
                                                <div className="flex justify-center">
                                                    <div
                                                        style={{
                                                            color: '#999',
                                                            fontSize: '12px',
                                                            marginTop: '8px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={showDrawer}
                                                    >
                                                        价格太贵？点击修改图表
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>

                                {/* 消息轮播 */}
                                {demoList && demoList.length > 0 && (
                                    <div className={styles.demoList}>
                                        <NotificationOutlined
                                            style={{ color: 'rgb(36, 190, 88)' }}
                                        />
                                        <Carousel
                                            style={{
                                                width: '300px',
                                                margin: '0 0 0 10px',
                                            }}
                                            dotPosition="left"
                                            autoplay={true}
                                            autoplaySpeed={1000}
                                        >
                                            {demoList.map((msg, idx) => (
                                                <div key={idx}>{msg}</div>
                                            ))}
                                        </Carousel>
                                    </div>
                                )}
                            </div>

                            {cnAbstractsCompleted && enAbstractsCompleted && (
                                <div className={styles.payTip} style={{ margin: '0 auto' }}>
                                    <div
                                        style={{
                                            color: '#999',
                                            fontSize: '12px',
                                            marginTop: '8px',
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                        }}
                                        onClick={showCodeModal}
                                    >
                                        我有兑换码
                                    </div>
                                </div>
                            )}
                            {/* 兑换码入口 */}
                        </div>
                    </div>
                </Content>
                {isMobile ? null : isShowExtra ? (
                    <Sider
                        width={'18%'}
                        style={{
                            ...siderStyle,
                            right: 0,
                            top: 68,
                            border: '1px solid #EEEEF1',
                            borderRadius: 12,
                            padding: 16,
                            height: 'calc(100vh - 80px)',
                        }}
                    >
                        <ExtraContent />
                    </Sider>
                ) : null}
                {isMobile ? (
                    isShowExtra ? (
                        <div className={'m-3 rounded-xl border border-[#EEEEF1] bg-white p-4'}>
                            <ExtraContent />
                        </div>
                    ) : null
                ) : null}
            </Layout>
            <Modal
                title="解锁全文"
                open={showPayModal}
                width={790}
                footer={null}
                onCancel={onCancel}
                maskClosable={false}
            >
                {showPayResult ? (
                    <div className={styles.payResult}>
                        <div
                            className={styles.payStatusBox}
                            style={payResult ? { padding: '16px 0 20px 0' } : {}}
                        >
                            <p className={styles.statusTitle}>
                                {payResult ? '恭喜您，支付成功' : '很遗憾，支付失败'}
                            </p>
                            <img
                                src={payResult ? success : error}
                                className={styles.statusImg}
                                style={payResult ? { margin: '16px 0 20px 0' } : {}}
                            />
                            {!payResult ? (
                                <Button className="action_btn" onClick={toRePay}>
                                    <span>重新支付</span>
                                </Button>
                            ) : (
                                <Button onClick={onCancel}>
                                    <span>完成</span>
                                </Button>
                            )}
                        </div>
                    </div>
                ) : (
                    <div style={{ margin: '30px 0 0 0' }}>
                        <Alert
                            message="付款成功前请不要关闭此页面。如付款后页面没有更新，请根据您的情况点击下方按钮"
                            type="warning"
                            showIcon
                            className="w-pay-alert"
                        />
                        <div className={styles.payContent}>
                            <div className={styles.left}>
                                <div
                                    className={styles.payTypeSwitch}
                                    style={{ marginBottom: '20px', textAlign: 'center' }}
                                >
                                    <Radio.Group
                                        onChange={(e) => {
                                            setPayType(e.target.value);
                                            submitPay(e.target.value);
                                        }}
                                        value={payType}
                                    >
                                        <Radio.Button value={1}>微信支付</Radio.Button>
                                        <Radio.Button value={2}>支付宝</Radio.Button>
                                    </Radio.Group>
                                </div>
                                <div className={styles.qrcode}>
                                    {payType === 1 ? (
                                        payInfoRef.current.codeUrl ? (
                                            <QRCode size={210} value={payInfoRef.current.codeUrl} />
                                        ) : null
                                    ) : null}
                                    {payType === 2 ? (
                                        alipayUrl ? (
                                            <iframe
                                                style={{
                                                    width: '170px',
                                                    height: '170px',
                                                    marginLeft: '10px',
                                                    marginTop: '10px',
                                                }}
                                                src={alipayUrl}
                                                title="支付宝支付"
                                            ></iframe>
                                        ) : (
                                            <div className="text-center text-sm text-[#999]">
                                                正在加载二维码...
                                            </div>
                                        )
                                    ) : null}
                                </div>
                                <div>{payType === 1 ? '微信扫码支付' : '支付宝扫码支付'}</div>
                                <div style={{ color: '#999', fontSize: '12px' }}>
                                    如遇支付问题，请联系客服微信
                                </div>
                                <p className="mt-2 w-[168px] text-xs text-[#999]">
                                    虚拟产品，支付前请仔细确认，结果由AI根据您提供的信息自动生成，仅供参考，支付成功后立即生效，不支持退款。
                                </p>
                            </div>
                            <div className={styles.right}>
                                <div className={styles.title}>{summaryStore.majorName}</div>
                                {countdown.formatted !== '00:00' ? (
                                    <div className={styles.discount}>
                                        <div className={styles.line}>
                                            <div className={styles.lineLeft}></div>
                                            <div className={styles.lineRight}></div>
                                        </div>
                                        <div>
                                            限时特惠 距活动结束：
                                            <span className={styles.countdown}>
                                                {countdown.formatted}
                                            </span>
                                        </div>
                                    </div>
                                ) : null}

                                <div className={styles.row}>
                                    <label>订单总额：</label>
                                    <div>
                                        <span className={styles.price}>
                                            ￥{paper.prices && paper.prices.totalDiscountedPrice}
                                            <span style={{ fontSize: '16px' }}>元</span>
                                        </span>
                                        <span className={styles.origin}>
                                            ￥{paper.prices && paper.prices.totalOriginalPrice}元
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <label>全文金额：</label>
                                    <div>
                                        <div>
                                            <span className={styles.price}>
                                                ￥{paper?.prices?.paperDiscountedPrice}
                                                <span style={{ fontSize: '16px' }}>元</span>
                                            </span>
                                            <span className={styles.origin}>
                                                ￥{paper?.prices?.paperOriginalPrice}元
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.row}>
                                    <label>专业内容：</label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div className={styles.price}>
                                            ￥{paper?.prices?.contentServiceDiscountedPrice}元
                                            <span className={styles.origin}>
                                                ￥{paper?.prices?.contentServiceOriginalPrice}元
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                color: '#666',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {counts.TABLE ? `[数据(表)x${counts.TABLE}]` : null}
                                            {counts.DIAGRAM ? ` [图x${counts.DIAGRAM}]` : null}
                                            {counts.FORMULA ? `[公式x${counts.FORMULA}]` : null}
                                            {counts.CODE ? `[代码段x${counts.CODE}]` : null}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.row} style={{ alignItems: 'flex-start' }}>
                                    <label>附加服务：</label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <div className={styles.price}>
                                            ￥{paper?.prices?.additionalServiceDiscountedPrice}元
                                            <span className={styles.origin}>
                                                ￥{paper?.prices?.additionalServiceOriginalPrice}元
                                            </span>
                                        </div>
                                        <div
                                            style={{
                                                color: '#666',
                                                fontSize: '12px',
                                            }}
                                        >
                                            {summaryStore.serviceList
                                                .filter((it) => it.checked)
                                                .map((i, idx) => (
                                                    <span key={idx}>[{i.sname}x1]</span>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.btnRow}>
                                    <Button
                                        onClick={checkPay}
                                        className={styles.btn}
                                        type="primary"
                                        shape="round"
                                    >
                                        我已支付成功
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal
                open={mobileCatalogueOpen}
                onCancel={() => {
                    setMobileCatalogueOpen(false);
                }}
                onOk={() => {
                    setMobileCatalogueOpen(false);
                }}
            >
                <Spin spinning={!summaryStore.catalogueDataLoaded}>
                    <Catalogue />
                </Spin>
            </Modal>
        </>
    );
});
export default Step3;
